'use client';

import '@/styles/global.css';

import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/react-ui';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

export default function NotFound() {
	const router = useRouter();

	const [hasHistory, setHasHistory] = useState<boolean>();

	useEffect(() => {
		setHasHistory(window.history.length > 1);
	}, []);

	return (
		<div className="container mx-auto my-16 flex flex-col justify-center">
			<div className="flex w-full items-center justify-between">
				<div className="flex-1 text-center text-3xl font-bold leading-7 md:text-4xl">
					Oops! The page you&apos;re looking for isn&apos;t here.
				</div>
			</div>
			<div className="mt-4 text-center text-lg font-normal text-gray-600">
				We&apos;re sorry, but the page you are trying to access could not be found. It may have been moved, deleted, or never existed. <br />{' '}
				<br />
				Please check the URL for typos or navigate back to the homepage. We apologize for any inconvenience.
			</div>

			<div className="flex justify-center gap-2 pt-6">
				{hasHistory && (
					<Button onClick={() => router.back()} variant="secondary" size="sm" prefixIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
						Back to Previous Page
					</Button>
				)}

				<Link href="/">
					<Button variant="secondary" size="sm" prefixIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
						Back to home
					</Button>
				</Link>
			</div>
		</div>
	);
}
